import React from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PAGE_INFO } from './__constants/constants';
import { About, Links, Projects, Root } from './organisms';

const router = createBrowserRouter([
  {
    path: PAGE_INFO._ROOT.path,
    element: <Root />,
    children: [
      {
        index: true,
        element: <Navigate to={PAGE_INFO.ABOUT.path} replace />
      },
      {
        path: PAGE_INFO.ABOUT.path,
        element: <About />
      },
      {
        path: PAGE_INFO.LINKS.path,
        element: <Links />
      },
      // {
      //   path: PAGE_INFO.OPEN_FORTRESS.path,
      //   element: <OpenFortress />
      // },
      {
        path: PAGE_INFO.PROJECTS.path,
        element: <Projects />
      }
    ]
  }
]);

export const WebApp = () => {
  return (
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>
  );
};